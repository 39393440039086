<template>
  <div class="dimmer active" :class="{'absolute-loading': positionAbsolute}">
    <div class="spinner4">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    positionAbsolute: {
      default: false,
      type: Boolean
    }
  }
}
</script>
<style scoped>
.absolute-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
