<template>
<!--    rounded-circle -->
  <section id="scroll-t-top" style="width: 40px;height: 40px" class="scroll-to-top bg-color-theme mb-md-0 mb-5">
    <button class="py-2" type="button" @click="scrollToTop0">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7256 4.25L11.7256 19.25" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.70124 10.2998L11.7252 4.2498L17.7502 10.2998" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </section>

</template>

<script>
export default {
  name: "ScrollToTop",
  methods:{
    scrollToTop0(){
      document.documentElement.scrollTo({
        top:0,
        behavior:"smooth"
      });

    }
  },
  mounted() {
    //****** scroll to top */
    let scrollToTop=document.getElementById('scroll-t-top');
    window.onscroll= function (){


      if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        scrollToTop.style.bottom="30px";

      }
      else if(document.body.scrollTop <= 500 || document.documentElement.scrollTop <= 500){
        scrollToTop.style.bottom="-43px";

      }
    };



  }
}
</script>

<style scoped>

</style>
