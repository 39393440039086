<template>
<span v-if="variety.final_price.discount_type == 'percentage'"
      class="recommended-discount bg-color-themeRed fontsize-mini text-white px-2">{{variety.final_price.discount}}%
                    تخفیف</span>
  <span v-else-if="variety.final_price.discount_type == 'flat'"
        class="recommended-discount bg-color-themeRed fontsize-mini text-white px-2"> تخفیف :
                    {{variety.final_price.discount | price}} تومان
                </span>
</template>

<script>
export default {
  name: "DiscountTag",
  props: {
    variety: Object
  }
}
</script>

<style scoped>

</style>
