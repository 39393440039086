export default {
    data() {
        return {
            _openModalId: null,
        }
    },
    mounted() {
        if (this.modals && this.modals.length) {
            this.modals.forEach(modalId => {
                let modalEl = window.$('#' + modalId);
                modalEl.off('shown.bs.modal', this._onModalOpen)
                modalEl.on('shown.bs.modal', this._onModalOpen)
                modalEl.off('hidden.bs.modal', this._onModalClose)
                modalEl.on('hidden.bs.modal', this._onModalClose)
            })
          window.removeEventListener("popstate", this._checkBackButton);
          window.addEventListener("popstate", this._checkBackButton);
        }
    },
    methods: {
        _onModalOpen(e) {
            this.$router.push(this.$route.fullPath + `#${e.target.id}`)
            this._openModalId = e.target.id
        },
        _onModalClose(e) {
            if (this.$route.fullPath.includes(this._openModalId)) {
                this._openModalId = null;
                this.$router.back()
            }
        },
        _checkBackButton() {
            if (!this.$route.fullPath.includes(this._openModalId)) {
                window.$('#' + this._openModalId).modal('toggle');
                this._openModalId = null;
            }
        }
    }
}